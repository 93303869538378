import React from 'react'
import './../styles/showTables.css'

function ShowTables({Tables}) {
  let x = 0;
  return (
    <div className='wrapperTableCard'>
        {
            Tables.map(item => (
                <div key={x++} className='tableCard'>
                    <h2 className='tableTitle'>{item?.TABLE_NAME}</h2>
                    <h4>{item?.TABLE_ROWS}</h4>
                </div>
            ))
        }
    </div>
  )
}

export default ShowTables