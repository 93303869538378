import React, {useState} from 'react'
import axios from 'axios';
import './../styles/form.css'
import swal from 'sweetalert';


function NewCity() {
    const [city_name, setCity] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!city_name){
            setError('Veillez saisir le nom de la ville');
        }
        else {
            try {
                const res = await axios.post('/create-city', {city_name});
                swal({
                    title: "Succès",
                    text: res.data.message,
                    icon: "success",
                })
                setCity('')

            } catch (error) {
                console.log(error);
                swal({
                    title: "Erreur",
                    text: error?.response?.data?.message,
                    icon: "error",
                })
            }
        }
    }

    return (
        <div className='divForm' onSubmit={handleSubmit}>
            
            <form method="post">
                <div className="div">
                    <h2>Nouvelle ville</h2>
                </div>
                <div className='div'>
                    <p><label htmlFor="city">Nom de ville</label></p>
                    <input type="text" name="" id="city" className='field' onChange={e=>{setCity(e.target.value)}} />
                </div>
                <div className='div'>
                        <input type="submit" value="Valider" className='field' />
                </div>
            </form>
        </div>
    )
}

export default NewCity