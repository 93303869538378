import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import getData from '../helpers/getData';
import './../styles/allUsers.css'

function ShowDistrict() {
    const [districts, setCitys] = useState([]);

    useEffect(()=>{getData('/districts', setCitys)}, []);
    return (
        <div>
            <div className='divNewLink'>
                <Link className='newLink' to='/newDistrict' >Nouveau</Link>
            </div>
            <div>
                <h2 className='list' >Listes des Quartiers</h2>
                {
                    districts?.map(district => (
                        <div key={district?.id_district} className='cityCard' >
                            <h3 style={{marginBottom : 12}}>{district?.district_name}</h3>
                            <i>{district?.city}</i>
                            <p className='pLink'>
                                <Link to={`/updateDistrict/${district?.id_district}/${district?.district_name}/${district.id_city}`}>Modifier</Link>
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ShowDistrict