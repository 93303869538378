import React, {useState, useEffect} from 'react'
import getData from '../helpers/getData';
import './../styles/allAds.css'

function ShowAds() {

    const [ads, setAds] = useState([]);

    useEffect(()=>{getData('/ads', setAds)}, []);

    return (
        <div>
            <h2 className='list'>Listes des Annonces</h2>
            {
                ads.map(ad => (
                    <div key={ad.id_ad} className='adCard'>
                        <div className='divImg'>
                            <img src={ad.url[0]} alt="" className='imgAd' />
                        </div>
                        <div className='divImg'>
                            <p className='adInfo'>{ad.price} F</p>
                            <p className='adInfo'>Ville : {ad.city}</p>
                            <p className='adInfo'>Quartier : {ad.district}</p>
                            <p className='adInfo'> Caution : {ad.caution}</p>
                        </div>
                        <div>
                            <p className='adInfo'>Type : {ad.type}</p>
                            <p className='adInfo'>Poster par {ad.ownerFirstName} {ad.ownerName}</p>
                            <p className='adInfo'>{ad.created_at}</p>
                            <p className='adInfo'>Disponible : {ad.available ? 'Oui' : 'Non'}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ShowAds