import axios from 'axios';

const getData = async (uri, setData) => {
    try {
        const res = await axios.get(uri);
        setData(res.data);
    } catch (error) {
        console.log(error);
    }
}

export default getData;