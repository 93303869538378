import React, { useState} from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios';
import './../styles/form.css'
import swal from 'sweetalert';

function UpdateRole() {

    const [level, setLevel] = useState('user');
    const [error, setError] = useState('');
    const {id} = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!level){
            setError('Veillez selectionnez le role');
        }
        else {
            try {
                const res = await axios.put(`/change-level/${id}`, {level});
                swal({
                    title: "Succès",
                    text: res.data.message,
                    icon: "success",
                })
                setLevel('')

            } catch (error) {
                console.log(error);
                swal({
                    title: "Erreur",
                    text: error?.response?.data?.message,
                    icon: "error",
                })
            }
        }
    }

   // useEffect(()=>{},[])


    return (
        <div className='divForm' onSubmit={handleSubmit}>
            
            <form method="post">
                <div className="div">
                    <h2>Modification du role <br/> de l'utilisateur</h2>
                </div>
                <i>{error}</i>
                <div className='div'>
                    <select name="id_city"  className='field' onChange={(e)=>{setLevel(e.target.value)}} value={level} >
                        <option value="user">Utilisateur</option>
                        <option value="redactor">Annonceur</option>
                     </select>
                </div>
                <div className='div'>
                        <input type="submit" value="Valider" className='field' />
                </div>
            </form>
        </div>
    )
}

export default UpdateRole