import React, { useState } from 'react';
import axios from 'axios';
import './../styles/login.css';

function Login({load}) {

    const [tel, setTel] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState('');
    const [check, setCheck] = useState(false);

    const regexTel = /^[0-9]+$/;
    const regexPass = /^[A-Za-z0-9]{8,}$/;
    const [visible, setVisible] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        setCheck(true);

        
        if (!tel) {
            setError("Veillez saisir le Numéro");
            setCheck(false);
        } 
        else if (!password) {
            setError("Veillez saisir le Mot de passe");
            setCheck(false);
        }
        else if (!regexTel.test(tel)) {
            setError("Le mot de passe invalide");
            setCheck(false);
        }
        else if (!regexPass.test(password)) {
            setError("Le numero conteint des caratères invalide");
            setCheck(false);
        }
        else {
          try {
            const res = await axios.post('/login', {tel, password});

            axios.defaults.headers.common = {"Authorization" : `Bearer ${res.data.token}`}
            load(true);
            setCheck(false);

          } catch (error) {
            setError(error?.response?.data.message);
            setCheck(false);
          }
        }

    };

    return (
        <div className='loginWrapper'>
            <div>
                <h1 className='titleLogin'>Bienvenue <br/> sur <br/> ZPanel</h1>
                <span className='error'>{error}</span>
                
                <form action="" method="post" onSubmit={handleLogin}>
                    <div className='wrapperField'>
                        <p className='pLabel'>
                            <label htmlFor="tel">Numero de Téléphone</label>
                        </p>
                        <input type="tel" name="tel" id="tel" className='inputLogin' onChange={(e)=>{setTel(e.target.value)}} />
                    </div>

                    <div className='wrapperField'>
                        <p className='pLabel'>
                            <label htmlFor="password">Mot de Passe</label>
                        </p>
                        <input type={visible ? "text" : "password"} name="password" id="password" className='inputLogin' onChange={(e)=>{setPassword(e.target.value)}}/>
                    </div>

                    <div className='wrapperField'>
                        <input type="checkbox" name="show" id="show" onChange={(e) => {setVisible(!visible)}} />
                    </div>

                    <div className='wrapperField'>
                        <input type="submit" value={check ? 'en cours...' : 'Se connecté'} className='submitLogin' />
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Login