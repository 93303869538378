import React, {useState, useEffect} from 'react'
import getData from '../helpers/getData';
import {Link} from 'react-router-dom';
import './../styles/allUsers.css';

function AllUsers() {
    const [users, setUsers] = useState([]);

    useEffect(()=>{getData('/users', setUsers)}, []);

    return (
        <div>
            <h2 className='list'>Listes des utilisateurs</h2>
            {
                users.map(user => (
                    <div key={user.id_user} className='userCard'>
                        <h2>{user.name} {user.firstName}</h2>
                        <p>Téléphone : {user.tel}</p>
                        <p>role : {user.level}</p>
                        <i>{user.created_at}</i>
                        <p className='pLink'> <Link to={`/updateRole/${user.id_user}`}>Modifier le role</Link> </p>
                    </div>
                ))
            }
        </div>
    )
}

export default AllUsers