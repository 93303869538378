import React, {useState, useEffect} from 'react'
import getData from '../helpers/getData';
import './../styles/mind.css';

function ShowMinds() {
    const [minds, setMinds] = useState([]);

    useEffect(()=>{getData('/minds', setMinds)}, []);

    return (
        <div>
            <h2 className="list">Listes des avis</h2>
            {
                minds.map(mind => (
                    <div key={mind.id_mind} className='mindCard' >
                        <h2>{mind.note}/10</h2>
                        <h4>Ecrit par : {mind.ownerName} {mind.ownerFirstName}</h4>
                        <p>{mind.content}</p>
                        <p className='pRight'>{mind.created_at}</p>
                    </div>
                ))
            }
        </div>
    )
}

export default ShowMinds