import React, {useState, useEffect} from 'react'
import getData from '../helpers/getData';
import './../styles/form.css'
import axios from 'axios';
import swal from 'sweetalert'

function NewDistrict() {

    const [citys, setCitys] = useState([]);
    const [id_city, setIdCity] = useState(null);
    const [district_name, setDistrictName] = useState(null);
    const [error, setError] = useState(null);

    const regexId = /^[0-9]+$/;
    

    useEffect(()=>{getData('/all-city', setCitys)}, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!id_city){
            setError('Veillez selectionnez une ville');
        }
        else if(!district_name) {
            setError('Veillez saisir le quartier');
        }
        else if(!regexId.test(id_city)) {
            setError('L\'identifiant de la ville est invalide');
        }
        else {
            try {
                const res = await axios.post('/create-district', {id_city, district_name});
                swal({
                    title: "Succès",
                    text: res.data.message,
                    icon: "success",
                })
                setIdCity(null);
                setDistrictName(null);

            } catch (error) {
                swal({
                    title: "Erreur",
                    text: error?.response?.data?.message,
                    icon: "error",
                })
            }
        }
    }

    if (citys.length > 0) {
        return (
            <div className='divForm'>
                <form method="post" onSubmit={handleSubmit}>
                    <div className='div'>
                        <h2>Nouveau quartier</h2>
                    </div>
                    <div className='div'>
                        <i>{error}</i>
                    </div>
                    <div className='div'>
                        <select name="id_city"  className='field' onChange={(e)=>{setIdCity(e.target.value)}} >
                            <option value="">Ville</option>
                            {
                                citys.map(city => (
                                    <option value={city.id_city} key={city.id_city}>{city.city_name}</option> 
                                ))
                            }
                        </select>
                    </div>
                    <div className='div'>
                        <p><label htmlFor="district"></label></p>
                        <input type="text" name="district_name" id="district" className='field' onChange={(e) =>{setDistrictName(e.target.value)}} />
                    </div>
    
                    <div className='div'>
                        <input type="submit" value="Valider" className='field' />
                    </div>
                    
                </form>
            </div>
        )
    }
    else{

    }

}

export default NewDistrict