import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import getData from '../helpers/getData';
import './../styles/allUsers.css'

function ShowCity() {
    const [citys, setCitys] = useState([]);

    useEffect(()=>{getData('/all-city', setCitys)}, []);
    return (
        <div>
            <div className='divNewLink'>
                <Link className='newLink' to='/newCity' >Nouveau</Link>
            </div>

            <div>
                <h2 className='list'>Listes des Villes</h2>
                {
                    citys.map(city => (
                        <div key={city.id_city} className='cityCard' >
                            <h3>{city.city_name}</h3>
                            <p className='pLink'>
                                <Link to={`/updateCity/${city.id_city}/${city.city_name}`}>Modifier</Link>
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ShowCity