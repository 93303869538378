import React, { useState } from 'react';
import {BrowserRouter, Routes, Route,NavLink} from 'react-router-dom';
import './../styles/dashboard.css'
import { useEffect } from 'react';
import ShowTables from '../components/ShowTables';
import getData from '../helpers/getData';
import AllUsers from '../components/AllUsers';
import ShowAds from '../components/ShowAds';
import ShowMinds from '../components/ShowMinds';
import ShowCity from '../components/ShowCity';
import ShowDistrict from '../components/ShowDistrict';
import NewDistrict from '../components/NewDistrict';
import UpdateDistrict from '../components/UpdateDistrict';
import NewCity from '../components/NewCity';
import UpdateCity from '../components/UpdateCity';
import UpdateRole from '../components/UpdateRole';

function Dashboard() {
    const [tables, setTables] = useState([]);
    //const redirect = useNavigate();

    useEffect(()=>{getData('/inftabact', setTables)}, []);

    return (
        <BrowserRouter>
            <div className='dashbordWrapper'>
                <label htmlFor="checkbox" id='label_checkbox'>Menu</label>
                <input type='checkbox' id='checkbox'/>
                <div className="header">
                    <h1 className='dashboardTitle'>ZPanel</h1>
                    <p className='pTable'><NavLink to='/' >Dashboard</NavLink></p>
                    <p className='pTable'><NavLink to='/users'>Utilisateurs</NavLink></p>
                    <p className='pTable'><NavLink to='/ads' >Annonces</NavLink></p>
                    <p className='pTable'><NavLink to='/minds' >Avis</NavLink></p>
                    <p className='pTable'><NavLink to='/city' >Villes</NavLink></p>
                    <p className='pTable'><NavLink to='/districts'>Quartier</NavLink></p>
                </div>

                <div className="main">
                    <Routes>
                        <Route path='/' element={<ShowTables Tables={tables}/>}/>
                        <Route path='/users' element={<AllUsers/>}/>
                        <Route path='/ads' element={<ShowAds/>}/>
                        <Route path='/minds' element={<ShowMinds/>}/>
                        <Route path='/city' element={<ShowCity/>}/>
                        <Route path='/districts' element={<ShowDistrict/>}/>
                        <Route path='/newDistrict' element={<NewDistrict/>}/>
                        <Route path='/newCity' element={<NewCity/>}/>
                        <Route path='/updateRole/:id' element={<UpdateRole/>}/>
                        <Route path='/updateCity/:id/:name/' element={<UpdateCity/>}/>
                        <Route path='/updateDistrict/:id/:name/:currentCity' element={<UpdateDistrict/>}/>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    )



}

export default Dashboard