import React, { useState } from 'react';
import axios from 'axios';
import Login from './pages/Login';
import './styles/main.css'
import Dashboard from './pages/Dashboard';

function App() {

    const [isLogin, setIsLogin] = useState(false);

    axios.defaults.baseURL = 'https://serverapi.toittrouvé.com:5443';

    


    if (!isLogin) {
        return (
            <Login load={setIsLogin}/>
        );
    }
    if (isLogin) {
        return <Dashboard/>
    }
}

export default App;